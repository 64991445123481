<template>
	<div class="mb-20">
		<div class="grid grid-cols-1 md:grid-cols-2 mb-4">
			<div class="col-span-1 flex items-center flex-wrap">
				<h2 class="text-2xl font-bold mr-3">Bank Statements</h2>
			</div>
			<div class="col-span-1">
				<search-form
					v-model="searchQuery"
					class="w-full"
					placeholder="Search for user's name, status, etc."
					@submit="$refs.table.loadAjaxData()"
				/>
			</div>
		</div>

		<div class="flex flex-col border border-solid border-blue-200">
			<div class="duration-300">
				<datatable
					:url="url"
					:ajax="true"
					:ajaxPagination="true"
					:index="true"
					:reverse-index="true"
					:data="statements.data"
					:columns="columns"
					:onClick="click"
					:pageDetails="true"
					:exportable="true"
					:query="searchQuery"
					:loading="statements.loading"
					:actions="actions"
					ref="table"
				/>
			</div>
		</div>

		<modal className="w-11/12 md:w-443px lg:w-443px" ref="markAsCompleted">
			<h1 class="text-2xl text-left font-bold mb-8">
				Add PIN
			</h1>
			<form @submit.prevent="submit">
				<template v-if="getFormError(form)">
					<div class="alert alert-red-soft mb-10">
						<span class="alert-icon">!</span>
						<span>{{ getFormError(form) }}</span>
					</div>
				</template>

				<form-group
					leftIcon="mail-outline"
					name="pin"
					:form="form"
					v-model="form.data.code.value"
				>
					Code
				</form-group>
				<div class="flex justify-center">
					<button
						class="btn btn-blue font-normal py-4 px-16"
						type="submit"
						:disabled="form.loading"
					>
						Submit
					</button>
				</div>
			</form>
		</modal>
	</div>
</template>
<script>
export default {
	data() {
		return {
			url: `${this.$baseurl}/admin/personal/users/bank-statement/all`,
			form: this.$options.basicForm([
				{ name: "code", rules: "required|aplpha" },
			]),
			statements: this.$options.resource([]),
			searchQuery: "",
			selectedStatement: null,
			actions: [
				{
					text: "In Progress",
					class:
						"border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200",
					action: this.inProgress,
					disabled: (statement) =>
						statement.status === "progress" || statement.status === "completed",
				},
				{
					text: "mark as completed",
					class:
						"border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200",
					action: this.completed,
					disabled: (statement) => statement.status === "completed",
				},
			],
			columns: [
				{
					name: "staff_name",
					th: "Full Name",
				},
				{
					name: "account_no",
					th: "Account No",
				},
				{
					name: "bank_name",
					th: "Bank Name",
				},
				{
					name: "phone",
					th: "Phone Number Attached To Account",
				},
				{
					name: "code",
					th: "Code",
				},
				{
					name: "verified_with",
					th: "Verification Method",
					render: (statement, verified_with) => {
						switch (verified_with) {
							case "mbs":
								return "<span>MBS</span>";
							case "okra":
								return "<span>OKRA</span>";
							case "mono":
								return "<span>MONO</span>";
							case "ussd":
								return "<span>USSD</span>";
							case "mobile":
								return "<span>Mobile App</span>";
							default:
								return "<span></span>";
						}
					},
				},
				{
					name: "status",
					th: "Status",
					render: (statement, status) => {
						switch (status) {
							case "completed":
								return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
			                    Completed
			                  </div>`;
							case "progress":
								return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-3 py-2 inline-block">
			                    In Progress
			                  </div>`;
							case "pending":
								return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
			                    Pending
			                  </div>`;
							default:
								return `<span></span>`;
						}
					},
				},
				{
					name: "created_at",
					th: "Created At",
					render: (statement, created_at) =>
						this.$moment(created_at).format("MMM Do YYYY"),
				},
			],
		};
	},
	methods: {
		click({ user_id }) {
			return this.$router.push({
				name: "staff-view",
				params: {
					staffId: user_id,
				},
			});
		},
		async inProgress({ id }) {
			await this.$get({
				url: `${this.$baseurl}/admin/personal/users/bank-statement/status/${id}`,
				headers: this.headers,
				success: () => {
					this.$refs.table.loadAjaxData();
				},
				error: (error) => {
					console.log(error);
				},
			});
		},
		completed(statement) {
			this.selectedStatement = statement;
			this.$refs.markAsCompleted.open();
		},
		async submit() {
			await this.$put({
				url: `${this.$baseurl}/admin/personal/users/bank-statement/completed/${this.selectedStatement.id}`,
				headers: this.headers,
				data: this.getFormData(),
				success: () => {
					this.resetForm(this.form);
					this.$refs.markAsCompleted.close();
					this.$refs.table.loadAjaxData();
					//this.getBankStatements();
				},
				error: (error) => {
					console.log(error);
				},
			});
		},
	},
};
</script>